<template>
    <div>
        <div class="d-flex justify-content-between align-items-center mb-n2">
        <h4 style="font-weight: 500">Stockage</h4>
        </div>
        <hr  />
        <mdb-card class="z-depth-1">
            <mdb-card-body class="card-reversed">
            <div>
                <mdb-row>
                    <mdb-col sm="12" md="6" lg="6" class="d-flex justify-content-between align-items-center" >
                        <div class="" v-show="$gate.hasPermission(['edit::setting'])">
                            <mdb-simple-chart :value="getTotalStorageUsed" :color="getcolorStorageRecap">
                            <div style="line-height:.9rem; text-align:center" > <b>{{getTotalStorageUsed}}%</b>
                                <div style="font-size:.7rem" >Stockage</div>
                                <div style="font-size:.7rem" >Utilisé</div>
                                </div>
                            </mdb-simple-chart>
                        </div>
                        <div class="" v-show="$gate.hasPermission(['edit::setting'])">
                            <table class="table dtdd" style="width:100%">
                                <tr class="bar_used">
                                    <th style="font-weight:500">Espace Utilisé</th>
                                    <td style="font-weight:500;min-width:100px;text-align:right">{{getTotalUsedGo}} Go</td>
                                </tr>
                                <tr class="bar_total">
                                    <th style="font-weight:500">Espace Disponible</th>
                                    <td style="font-weight:500;min-width:100px;text-align:right">{{getTotalGo}} Go</td>
                                </tr>
                            </table>
                        </div>
                    </mdb-col>
                    <mbd-col>
                         <hr class="" />
                        <div class="card z-depth-1" v-loading="loading">
                        <div class="card-body">
                            <div class="d-flex justify-content-end mb-2">
                                <mdb-btn size="md" color="primary"  @click="$router.push('/parametres/storage/pricing')">Augmenter le stockage</mdb-btn>
                            </div>
                            <mdb-tbl responsive >
                                <mdb-tbl-head>
                                    <tr>
                                    <th>N° Facture</th>
                                    <th class="text-left">Date</th>
                                    <th class="text-right">Montant</th>
                                    <th class="text-right">Actions</th>
                                    </tr>
                                </mdb-tbl-head>
                                <mdb-tbl-body>
                                    <tr class="tec-table-row" v-for="sub in queriedData" :key='sub.id'>
                                        <td>{{sub.num_facture}}</td>
                                        <td class="tec-table-cell text-left"> {{ $moment(sub.created_at).format("YYYY/MM/DD H:m:s") }}</td>
                                        <td class="tec-table-cell text-right">{{sub.amount | formatNumber}}   </td>
                                        <td class="tec-table-cell text-right">
                                            <button href="#" @click="exportPdfRecu(sub)" class="btn btn-link btn-sm text-info px-1 py-1 mx-1">Reçu</button>
                                            <button href="#" @click="exportPdfFN(sub)"  class="btn btn-link btn-sm text-info px-1 py-1 mx-1">Facture</button>
                                        </td>
                                        </tr>
                                </mdb-tbl-body>
                            </mdb-tbl>
                            <div class="d-flex justify-content-end">
                                <tec-pagination
                                    v-model="dataPagination.pagination.currentPage"
                                    :per-page="parseFloat(dataPagination.pagination.perPage)"
                                    :total="dataPagination.pagination.total"
                                ></tec-pagination>
                            </div>
                        </div>
                        </div>
                    </mbd-col>
                </mdb-row>
            </div>
            </mdb-card-body>
        </mdb-card>
    </div>
</template>

<script>
import {
    // mdbInput,
    // mdbRow,mdbCol,
    mdbCard,mdbCardBody,
    mdbSimpleChart,
    mdbTbl,
    mdbTblHead,
    mdbTblBody,
    mdbBtn
    // mdbSwitch,
} from 'mdbvue';
import TecPagination from "@/tba/components/Pagination";
import download from "@/services/download-fetch"
export default {
    metaInfo: {
      title: 'TECBILL APP',
      titleTemplate: '%s - PARAMETRE STOCKAGE',
      htmlAttrs: {
        lang: 'fr',
        amp: true
      }
    },
    components:{
        // mdbInput,
        // mdbRow,mdbCol,
        mdbCard,mdbCardBody,
        mdbSimpleChart,
        mdbTbl,
        mdbTblHead,
        mdbTblBody,
        mdbBtn,
        TecPagination

        // mdbSwitch,
    },
    data(){
        return{
            storage:[],
            dataPagination: {
                pagination: {
                    perPage:10,
                    currentPage: 1,
                    perPageOptions: [
                        {text:"10",value:"10", selected: true},
                        {text:"25",value:"25"},
                        {text:"50",value:"50"},
                        {text:"100",value:"100"},
                    ],
                    total: 0,
                },
                searchQuery: "",
                propsToSearch: [
                    "famille",
                    "ref",
                    "designation",
                    "describe",
                    "unit",
                    "puht",
                ],
            },

            empty:false,
            loading:false,

            data:{
                columns: [],
                rows:[]
            },
        }
    },

    methods:{
        async getStorageRecap(){
            this.$nprogress.start()
            await this.$store.dispatch('report/storageRecapReports')
            .then((response)=>{
                this.$nprogress.done()
                this.storage = response.data.storage;
            })
            .catch((error)=>{
                this.$nprogress.done()
                this.$notify({
                    message: error.response.data.message  || error.response.data.error,
                    type: 'danger'
                })
            });
        },
        async loadSubscritionSpaces() {
        this.$nprogress.start();
        this.loading = !this.loading
        await this.$store
            .dispatch("subscription_space/findAll")
            .then((response) => {
                this.loading = !this.loading

                this.$nprogress.done();
                this.data.rows = response.data.subscriptions
            })
            .catch((error) => {
            this.$nprogress.done();
            this.loading = !this.loading

            this.$notify({
                
                message: error.response.data.message || error.response.data.error,
                type: "danger",
            });
            });
        },

        async exportPdfRecu(sub){
            await download({
                url:'/subscription_spaces/'+sub.id+'?r=1',
                method:"get",
                data:[],
                isTh:0,
                filename:'r-'+sub.num_facture.toLowerCase()+'.pdf'
            }).then(() => {
                this.$notify({
                    message: "PDF Généré avec succès",
                    type: 'success'
                })
            })
            .catch(() => {
                this.$notify({
                    
                    message: "Echec durant l'exportation, reessayer ulterieurement",
                    type: 'danger'
                })
            })
        },

        async exportPdfFN(sub){
            await download({
                url:'/subscription_spaces/'+sub.id+'?f=1',
                method:"get",
                data:[],
                isTh:0,
                filename:'f-'+sub.num_facture.toLowerCase()+'.pdf'
            }).then(() => {
                this.$notify({
                    message: "PDF Généré avec succès",
                    type: 'success'
                })
            })
            .catch(() => {
                this.$notify({
                    
                    message: "La facture normalisée n'est pas encore prête.",
                    type: 'danger'
                })
            })
        },

        // Systeme de datatable

        totalCalcul () {
            return this.dataPagination.pagination.total = this.data.rows.length;
        },

        searchQ () {
            if (!this.dataPagination.searchQuery) {
                // return this.pagedData;
                this.totalCalcul()
                return new Error('Nothing To Search')
            }
            
            let result = this.data.rows.filter((row) => {
                let isIncluded = false;
                for (let key of this.dataPagination.propsToSearch) {
                    let rowValue = "";

                    if(row[key] !== undefined && row[key] !== null){
                        rowValue = row[key].toString().toLowerCase();
                    }
                    if (rowValue.includes && rowValue.includes(this.dataPagination.searchQuery.toLowerCase())) {
                    isIncluded = true;
                    }
                }
                return isIncluded;
            });
            this.dataPagination.pagination.total = result.length;
            return result.slice(this.from, this.to);
        },

        nbrPerPageSelected(value){
            let nbr = value;
            
            return this.nbrPerPage(nbr)
        },

        nbrPerPage (nbr) {
            return this.dataPagination.pagination.perPage = nbr
        },
    },
    computed:{
        getTotalStorage(){
            return this.storage.size_space
        },

        getTotalGo(){
            return this.storage.size_in_go
        },
        getTotalUsedGo(){
            return this.storage.size_used_in_go
        },

        getTotalInvoiceCount(){
            return this.storage.invoice_count
        },

        getTotalStorageUsed(){
            let result = 0;
            result = Math.round((this.getTotalInvoiceCount * 100 ) /this.getTotalStorage)
            return result
        },
        
        getcolorStorageRecap(){
            let result = "#28a745"

            if(this.getTotalStorageUsed <= 50 ){
                result = '#28a745'
            }else if(this.getTotalStorageUsed <= 75){
                result = '#ffc107'

            }else{
                result = '#dc3545'
            }

            return result
        },

        pageData () {
            return this.data.rows.slice(this.from, this.to);
        },

        to() {
            let highBound = this.from + this.dataPagination.pagination.perPage;
            if (this.total < highBound) {
                highBound = this.total;
            }
            return highBound;
        },

        queriedData() {
            let result = this.searchQ();
            return (result instanceof Error) ? this.pageData : result;
        },

        from() {
            return this.dataPagination.pagination.perPage * (this.dataPagination.pagination.currentPage - 1);
        },

        total() { 
            return this.totalCalcul();
        },
    },
    created(){
        if(this.$gate.hasPermission(['edit::setting'])){
            this.getStorageRecap()
        }
        this.loadSubscritionSpaces()
  },
}
</script>

<style lang="scss">
#theme-light{
    tr.bar_used{
        th,td{
            border: 1px solid transparent !important;
        }
        position: relative;
        &::after{
            content: ' ';
            display: block;
            position: absolute;
            width: 100%;
            height: 5px;
            bottom: 0;
            left: 0;right: 0;
            background: var(--l-success);
        }
    }

    tr.bar_total{
        th,td{
            border: 1px solid transparent !important;
        }
        position: relative;
        &::after{
            content: ' ';
            display: block;
            position: absolute;
            height: 5px;
            bottom: 0;
            left: 0;right: 0;

            background: var(--l-primary);
        }
    }
}

#theme-dark{
    tr.bar_used{
        th,td{
            border: 1px solid transparent !important;
        }
        position: relative;
        &::after{
            content: ' ';
            display: block;
            position: absolute;
            width: 100%;
            height: 5px;
            bottom: 0;
            left: 0;right: 0;
            background: var(--l-success);
        }
    }

    tr.bar_total{
        th,td{
            border: 1px solid transparent !important;
        }
        position: relative;
        &::after{
            content: ' ';
            display: block;
            position: absolute;
            height: 5px;
            bottom: 0;
            left: 0;right: 0;

            background: var(--l-primary);
        }
    }
}

</style>